@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import-normalize;

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Space Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus-visible {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.gallery {
  width: 80%;
}

.gallery .carousel .thumbs-wrapper {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery .carousel .thumbs {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  white-space: normal;
}

.gallery .carousel .thumb {
  padding: 0px;
  width: 100%;
  white-space: normal;
  font-size: 12px;
  margin-right: 0px;
}

@media only screen and (max-width: 768px) {
  .gallery .carousel .thumbs {
    flex-direction: column;
    align-items: center;
  }
}